import {
  BookmarkedSkills, ContentKeyword, ContentPage, ContentQuiz, ContentResourceAssessment, Profile,
} from '@gomycode/types-utils/Types/types';

export type TrackStats = {
    skills: number;
    learningStyles: number;
    assessments: number;
    quizzes: number;
    reorderChallenges: number;
    fillInTheBlanks: number;
    codeCompilings: number;
    checkpoints: number;
}

export type Node = {
    id: string,
    name: string,
    description: string,
    estimatedDuration: number,
    kind: number,
    state: number,
    flags: number,
    children: Node[],
}

export type ExtendedNode = {
  id: string,
  name: string,
  nameFr: string,
  description: string,
  estimatedDuration: number,
  kind: number,
  state: number,
  flags: number,
  children: ExtendedNode[],
  students: Profile[]
};

export type ResourcesApiResponse = {
  content: {
    pages: ContentPage[];
  };
  assessments: ContentResourceAssessment[];
  keywords: ContentKeyword[];
}

export type Language = 0 | 1;

export type Keyword = {
  text: string;
  url: string;
};

export type Questions = {
  id: string;
  text: string;
  hasCodeExecution: boolean;
  languageCodeId: number;
  code: string;
  options: {
    text: string;
    isCorrect: boolean;
    id: string;
  }[];
};

export type SkillQuiz = {
  id: string;
  questions: Questions[];
};

export type Pages = {
  text: string;
};

export type Content = {
  pages: Pages[];
};

export type SkillResources = {
  languages: Language[];
  contentEn: Content;
  contentFr: Content;
  keywordsEn: Keyword[];
  keywordsFr: Keyword[];
  quizEn: SkillQuiz;
  quizFr: SkillQuiz;
};

export type ContentKeywordTranslation = {
  url: string;
  text: string;
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export type TrackPreviewState = {
  navigationIndex: number;
  activeStudents: any;
  skeleton: ExtendedNode;
  fetchSkeletonLoading: boolean;
  getActiveStudentsLoading: boolean;
  fetchSkeletonErrors: [];
  getActiveStudentsErrors: [];
  allActiveStudents: any;
  event: { workshop: {}; oneToOne: {}; checkpoint: {} };
  parsedSkill: any;
  skill: any;
  getItemErrors: [];
  getItemLoading: boolean;
  currentLearningStyleId: string;
  patchBookMarkErrors: [];
  patchBookMarkLoading: boolean;
  showBookMarkAlert: boolean;
  getBookMarkedNodesLoading: boolean;
  getBookMarkedNodesErrors: [];
  bookMarkedNodes: BookmarkedSkills[];
  curriculumState: boolean;
  feedbackModal: boolean;
  postSkillFeedbackLoading: boolean;
  postSkillFeedbackErrors: [];
  showSuccessModal: boolean;
  patchResourcesLoading: boolean;
  patchResourcesErrors: [];
  getResourcesLoading: boolean;
  getResourcesErrors: [];
  instructorResources: [];
  shouldRefetshResource: boolean;
  addModal: boolean;
  editModal: boolean;
  failedNotification: any;
  instructorResourcesBySuperSkill: [];
  getTrackStatsLoading: boolean;
  getTrackStatsErrors: [];
  trackStats: TrackStats;
  refetchBookMarked: boolean;
  unsavedModal: boolean;
  getOrderedCheckpointsLoading: boolean;
  getOrderedCheckpointsErrors: [];
  getOrderedWorkshopsLoading: boolean;
  getOrderedWorkshopsErrors: [];
  getOrderedOneToOnesLoading: boolean;
  getOrderedOneToOnesErrors: [];
  orderedCheckpoints: any;
  orderedOneToOnes: any;
  orderedWorshops: any;
  paginationIndex: number;
  hasMore: boolean;
  failedNotificationResource: any;
  showCurriculum: boolean;
  getResourcesBySkillIdLoading: boolean;
  resources: [];
  getAssessmentByIdLoading: boolean;
  quiz: ContentQuiz;
};
export const ITEM_KIND = {
  TRACK: { index: 0, name: 'TRACK' },
  SKILL: { index: 1, name: 'SKILL' },
  SUPER_SKILL: { index: 2, name: 'SUPER_SKILL' },
  CHECKPOINT: { index: 3, name: 'CHECKPOINT' },
  ONE_TO_ONE: { index: 5, name: ' ONE_TO_ONE' },
  WORKSHOP: { index: 4, name: ' WORKSHOP' },
  ONE_TO_ONE_AND_WORKSHOP: { index: 7, name: '  ONE_TO_ONE_AND_WORKSHOP' },
  REWARD: { index: 6, name: ' REWARD' },
};
