import { Tooltip } from 'antd';

export const REQUEST = 'REQUEST';
export const SUCCESS = 'SUCCESS';
export const FAILURE = 'FAILURE';
export const GET = 'get';
export const URL_SEPARATOR = '/';

export const ROLES = {
  content_creator: 'CONTENT_CREATOR',
  student: 'STUDENT',
  salesAgent: 'SALES_AGENT',
  operation_manager: 'OPERATION_MANAGER',
  assistant_operation_manager: 'ASSISTANT_OPERATION_MANAGER',
  instructor: 'INSTRUCTOR',
  central_operation_manager: 'COM',
  recruiter: 'RECRUITER',
  staff: 'STAFF',
};

export const LINK_REGEX = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
export const EMAIL_REGEX = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
export const DIGITS_ONLY_REGEX = /^[0-9]*$/;

export const COMMENT_MOMENT_FORMAT = 'llll';
export const TABLE_AND_PROFILE_MOMENT_FORMAT = 'YYYY/MM/DD HH:mm';
export const MOMENT_DATE_TIME_FORMAT = 'YYYY/MM/DD HH:mm';
export const MOMENT_DATE_FORMAT = 'YYYY/MM/DD';
export const MOMENT_TIME_FORMAT = 'HH:mm';
export const MOMENT_TIME_WITH_SECONDS_FORMAT = 'HH:mm:ss';
export const MOMENT_DATE_TIME_WITH_SECONDS_FORMAT = 'YYYY/MM/DD HH:mm:ss';
export const MOMENT_DAY_FORMAT = 'DD';
export const MOMENT_HOUR_FORMAT = 'HH';
export const MOMENT_HOUR_MINUTE_FORMAT = 'HHmm';
export const MOMENT_DATE_HOUR_MINUTE_FORMAT = 'YYYY/MM/DD HH:mm';
export const MOMENT_DATE_DAY_MONTH_YEAR_FORMAT = 'DD.MM.YY';
export const MOMENT_DATE_DAY_MONTH_YEAR_SHORT_PAY_FORMAT = 'll';
export const MOMENT_TEXT = 'dddd, MMM Do - H:mm';
export const MOMENT_DATE_TEXT = 'DD MMM ';

export const sunday = 0;
export const monday = 1;
export const tuesday = 2;
export const wednesday = 3;
export const thursday = 4;
export const friday = 5;
export const saturday = 6;
export const DAYS_LIST = {
  [sunday]: { key: 'sunday' },
  [monday]: { key: 'monday' },
  [tuesday]: { key: 'tuesday' },
  [wednesday]: { key: 'wednesday' },
  [thursday]: { key: 'thursday' },
  [friday]: { key: 'friday' },
  [saturday]: { key: 'saturday' },
};
export const DAYS_INDEX = {
  [DAYS_LIST[sunday].key]: sunday,
  [DAYS_LIST[monday].key]: monday,
  [DAYS_LIST[tuesday].key]: tuesday,
  [DAYS_LIST[wednesday].key]: wednesday,
  [DAYS_LIST[thursday].key]: thursday,
  [DAYS_LIST[friday].key]: friday,
  [DAYS_LIST[saturday].key]: saturday,
};

export const TN_COUNTRY = 'TN';
export const FR_COUNTRY = 'FR';
export const DZ_COUNTRY = 'DZ';
export const LB_COUNTRY = 'LB';
export const EG_COUNTRY = 'EG';
export const BH_COUNTRY = 'BH';
export const MA_COUNTRY = 'MA';
export const COUNTRIES = {
  [TN_COUNTRY]: { id: TN_COUNTRY, value: 'tunisia' },
  [FR_COUNTRY]: { id: FR_COUNTRY, value: 'france' },
  [DZ_COUNTRY]: { id: DZ_COUNTRY, value: 'algeria' },
  [LB_COUNTRY]: { id: LB_COUNTRY, value: 'libya' },
  [EG_COUNTRY]: { id: EG_COUNTRY, value: 'egypt' },
  [BH_COUNTRY]: { id: BH_COUNTRY, value: 'bahrain' },
  [MA_COUNTRY]: { id: MA_COUNTRY, value: 'morocco' },
};
export const hideLongText = (text: string, maxLength = 20) => {
  const allText = <span className="hide-long-text">{text}</span>;
  if (text?.length > maxLength) {
    return (
      <Tooltip placement="right" title={allText.props.children}>
        <span className="hide-long-text">
          {`${text?.slice(
            0,
            maxLength,
          )}...`}
        </span>
      </Tooltip>
    );
  }

  return text;
};

export const EN = 0;
export const FR = 1;
