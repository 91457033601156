import { Reducer } from 'redux';
import { message } from 'antd';
import { CastLocation, CastProfile } from '@gomycode/types-utils/Types/Casters';
import constants from './constants';
import { ProfileState } from './types';

// Type-safe initialState!
export const initialState: ProfileState = {
  errors: undefined,
  loading: false,
  profile: {
    ...CastProfile(),
    createdAt: '',
    roles: [],
    preferredLanguage: 0,
  },
  location: CastLocation(),
  discordURLs: [],
  updateProfileLoading: false,
  showUpdateProfileSuccessModal: false,
  changePasswordLoading: false,
  profiles: [],
  getProfilesLoading: false,
  switchProfileLoading: false,
  fetchInstructorDetailsLoading: false,
  fetchInstructorDetailsErrors: [],
  instructorDetails: { isTerminated: false },
  mainHsm: {},
  getMainHsmLoading: false,
  getMainHsmErrors: [],

};

// eslint-disable-next-line default-param-last
const reducer: Reducer<ProfileState> = (state = initialState, action) => {
  switch (action?.type) {
    case constants.getProfile.request:
      return { ...state, loading: true };

    case constants.getProfile.success: {
      return {
        ...state,
        loading: false,
        profile: action?.data.model.profile,
        location: action?.data.model.location,
      };
    }
    case constants.getProfile.failure: {
      return { ...state, loading: false, errors: [] };
    }

    case constants.fetchDiscordURLs.request:
      return {
        ...state,
        loadingDiscord: true,
      };
    case constants.fetchDiscordURLs.success:
      return {
        ...state,
        discordURLs: action.data.model,
      };

    case constants.updateProfile.request: {
      return {
        ...state,
        updateProfileLoading: true,
      };
    }

    case constants.updateProfile.success:
      return {
        ...state,
        updateProfileLoading: false,
        profile: action.data.model,
        showUpdateProfileSuccessModal: true,
      };

    case constants.toggleShowSuccessModal.success:
      return {
        ...state,
        showUpdateProfileSuccessModal: !state.showUpdateProfileSuccessModal,
      };

    case constants.changePassword.request:
      return {
        ...state,
        changePasswordLoading: true,
      };
    case constants.changePassword.success:
      return {
        ...state,
        showUpdateProfileSuccessModal: true,

        changePasswordLoading: false,
      };
    case constants.changePassword.failure:
      message.error(action.e?.response?.data?.model?.other[0]);
      return {
        ...state,

        changePasswordLoading: false,
      };

    case constants.getProfilesList.request:
      return {
        ...state,
        getProfilesLoading: true,
      };
    case constants.getProfilesList.success:
      return {
        ...state,
        getProfilesLoading: false,
        profiles: action.data.model,
      };
    case constants.getProfilesList.failure:
      return {
        ...state,
        getProfilesLoading: false,
      };

    case constants.switchProfile.request:
      return {
        ...state,
        switchProfileLoading: true,
      };

    case constants.switchProfile.success:

      localStorage.setItem('token', action.data.model.accessToken);
      localStorage.setItem('expires', action.data.model.expires);

      window.location.reload();
      return {
        ...state,
        switchProfileLoading: false,
      };

    case constants.switchProfile.failure:
      return {
        ...state,
        switchProfileLoading: false,
      };

    case constants.switchProfileWithRedirection.request:
      return {
        ...state,
        switchProfileLoading: true,
      };

    case constants.switchProfileWithRedirection.success:
      localStorage.setItem('token', action.data.model.accessToken);
      localStorage.setItem('expires', action.data.model.expires);

      window.location.href = action.data.model.redirectUrl;
      return {
        ...state,
        switchProfileLoading: false,
      };

    case constants.switchProfileWithRedirection.failure:
      return {
        ...state,
        switchProfileLoading: false,
      };
    case constants.fetchInstructorDetails.request:
      return { ...state, fetchInstructorDetailsLoading: true };

    case constants.fetchInstructorDetails.success: {
      return {
        ...state,
        fetchInstructorDetailsLoading: false,
        instructorDetails: action?.data.model,

      };
    }
    case constants.fetchInstructorDetails.failure: {
      return { ...state, fetchInstructorDetailsLoading: false, fetchInstructorDetailsErrors: [] };
    }
    case constants.getMainHsm.request:
      return {
        ...state,
        getMainHsmErrors: [],
        getMainHsmLoading: true,
      };

    case constants.getMainHsm.success:
      return {
        ...state,
        getMainHsmLoading: false,
        mainHsm: action.data.model,
      };

    case constants.getMainHsm.failure:
      return {
        ...state,
        getMainHsmErrors: [],
        getMainHsmLoading: false,
      };

    default: {
      return state;
    }
  }
};

export { reducer as profileReducer };
