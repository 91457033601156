import { generateActionTypes } from '../../shared/utils/generic-saga';

const root = 'app/InstructorCheckpoints/';

const constants = {
  getUnclosedCheckpoints: generateActionTypes(root, 'GET_UNCLOSED_CHECKPOINTS'),
  getClosedCheckpoints: generateActionTypes(root, 'GET_CLOSED_CHECKPOINTS'),
  getUnlockedCheckpoints: generateActionTypes(root, 'GET_UNLOCKED_CHECKPOINTS'),
  getAllCheckpoints: generateActionTypes(root, 'GET_ALL_CHECKPOINTS'),
  getCheckpointById: generateActionTypes(root, 'GET_CHECKPOINT_BY_ID'),
  addReview: generateActionTypes(root, 'ADD_REVIEW'),
  approveCheckpoint: generateActionTypes(root, 'APPROVE_CHECKPOINT'),
  approveWoolfCheckpoint: generateActionTypes(root, 'APPROVE_WOOLF_CHECKPOINT'),
  updateRateStudentTabStatus: generateActionTypes(
    root,
    'UPDATE_RATE_STUDENT_TAB_STATUS',
  ),
  fetchCheckpointTopStudents: generateActionTypes(
    root,
    'FETCH_CHECKPOINT_TOP_STUDENTS',
  ),
  showValidateCheckpointModal: generateActionTypes(
    root,
    'SHOW_VALIDATE_CHECKPOINT_MODAL',
  ),
  showDeadlineModal: generateActionTypes(
    root,
    'SHOW_DEADLINE_MODAL',
  ),
};

export default constants;
