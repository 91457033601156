import {
  all, put, call, takeEvery,
} from 'redux-saga/effects';
import * as automatedOneToOneApis from '../../shared/services/instructor/automatedOneToOnes';
import constants from './constants';

export function* fetchAutomatedOneToOneById(action: {
  type: string,
  values: {
    query: [];
    oneToOneAssignmentId: string;
  }
}) {
  try {
    const result: ReturnType<typeof automatedOneToOneApis.fetchAutomatedOneToOneById> = yield call(automatedOneToOneApis.fetchAutomatedOneToOneById, action.values);
    yield put({
      type: constants.fetchAutomatedOneToOneById.success,
      data: result,
    });
  } catch (e) {
    yield put({
      type: constants.fetchAutomatedOneToOneById.failure,
      e,
    });
  }
}

export function* fetchAutomatedOneToOneByIdWatcher() {
  yield takeEvery(constants.fetchAutomatedOneToOneById.request, fetchAutomatedOneToOneById);
}

export function* updateStudentAnswer(action: {
  type: string,
  id: string;
  body: {};
}) {
  try {
    const result: ReturnType<typeof automatedOneToOneApis.updateStudentAnswer> = yield call(automatedOneToOneApis.updateStudentAnswer, action.id, action.body);
    yield put({
      type: constants.updateStudentAnswer.success,
      data: result,
    });
  } catch (e) {
    yield put({
      type: constants.updateStudentAnswer.failure,
      e,
    });
  }
}

export function* updateStudentAnswerWatcher() {
  yield takeEvery(constants.updateStudentAnswer.request, updateStudentAnswer);
}

export function* updateStudentWoolfAnswer(action: {
  type: string,
  id: string;
  body: {};
}) {
  try {
    const result: ReturnType<typeof automatedOneToOneApis.updateStudentWoolfAnswer> = yield call(automatedOneToOneApis.updateStudentWoolfAnswer, action.id, action.body);
    yield put({
      type: constants.updateStudentWoolfAnswer.success,
      data: result,
    });
  } catch (e) {
    yield put({
      type: constants.updateStudentWoolfAnswer.failure,
      e,
    });
  }
}

export function* updateStudentWoolfAnswerWatcher() {
  yield takeEvery(constants.updateStudentWoolfAnswer.request, updateStudentWoolfAnswer);
}

export function* setQuestionIndex(action: {
  type: string;
  body: number;
}) {
  try {
    yield put({
      type: constants.setQuestionIndex.success,
      data: action.body,
    });
  } catch (e) {
    yield put({
      type: constants.setQuestionIndex.failure,
      e,
    });
  }
}

export function* setQuestionIndexWatcher() {
  yield takeEvery(constants.setQuestionIndex.request, setQuestionIndex);
}

export function* handleBackFromFeedback(action: {
  type: string;
  body: number;
}) {
  try {
    yield put({
      type: constants.handleBackFromFeedback.success,
      data: action.body,
    });
  } catch (e) {
    yield put({
      type: constants.handleBackFromFeedback.failure,
      e,
    });
  }
}

export function* handleBackFromFeedbackWatcher() {
  yield takeEvery(constants.handleBackFromFeedback.request, handleBackFromFeedback);
}

export function* sendInstructorFeedback(action: {
  type: string;
  id: string;
  body: {};
}) {
  try {
    const result: ReturnType<typeof automatedOneToOneApis.sendInstructorFeedback> = yield call(automatedOneToOneApis.sendInstructorFeedback, action.id, action.body);
    yield put({
      type: constants.sendInstructorFeedback.success,
      data: result,
    });
  } catch (e) {
    yield put({
      type: constants.sendInstructorFeedback.failure,
      e,
    });
  }
}

export function* sendInstructorFeedbackWatcher() {
  yield takeEvery(constants.sendInstructorFeedback.request, sendInstructorFeedback);
}

export function* sendInstructorWoolfFeedback(action: {
  type: string;
  values: {
    body: {};
    id: string;
  };
}) {
  try {
    const result: ReturnType<typeof automatedOneToOneApis.sendInstructorWoolfFeedback> = yield call(automatedOneToOneApis.sendInstructorWoolfFeedback, action.values);
    yield put({
      type: constants.sendInstructorWoolfFeedback.success,
      data: result,
      request: action.values,
    });
  } catch (e) {
    yield put({
      type: constants.sendInstructorWoolfFeedback.failure,
      e,
    });
  }
}
export function* sendInstructorWoolfFeedbackWatcher() {
  yield takeEvery(constants.sendInstructorWoolfFeedback.request, sendInstructorWoolfFeedback);
}

export function* setShowModal(action: {
  type: string;
  body: boolean;
}) {
  try {
    yield put({
      type: constants.setShowModal.success,
      data: action.body,
    });
  } catch (e) {
    yield put({
      type: constants.setShowModal.failure,
      e,
    });
  }
}

export function* setShowModalWatcher() {
  yield takeEvery(constants.setShowModal.request, setShowModal);
}

export function* resetOneToOneSettings() {
  try {
    yield put({
      type: constants.resetOneToOneSettings.success,
    });
  } catch (e) {
    yield put({
      type: constants.resetOneToOneSettings.failure,
      e,
    });
  }
}

export function* resetOneToOneSettingsWatcher() {
  yield takeEvery(constants.resetOneToOneSettings.request, resetOneToOneSettings);
}

export function* setShowInstructorFeedback(action: {
  type: string;
  body: boolean;
}) {
  try {
    yield put({
      type: constants.setShowInstructorFeedback.success,
      data: action.body,
    });
  } catch (e) {
    yield put({
      type: constants.setShowInstructorFeedback.failure,
      e,
    });
  }
}

export function* setShowInstructorFeedbackWatcher() {
  yield takeEvery(constants.setShowInstructorFeedback.request, setShowInstructorFeedback);
}

export default function* AutomatedOneToOneSaga() {
  yield all([
    fetchAutomatedOneToOneByIdWatcher(),
    updateStudentAnswerWatcher(),
    setQuestionIndexWatcher(),
    handleBackFromFeedbackWatcher(),
    sendInstructorFeedbackWatcher(),
    setShowModalWatcher(),
    resetOneToOneSettingsWatcher(),
    setShowInstructorFeedbackWatcher(),
    sendInstructorWoolfFeedbackWatcher(),
    updateStudentWoolfAnswerWatcher(),
  ]);
}
