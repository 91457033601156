import { Routes, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { NavbarTest } from '@gomycode/design-system';
import { Switch } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import * as Sentry from '@sentry/react';
import Mixpanel from 'mixpanel-browser';
import { Location } from '@gomycode/types-utils/Types/types';
import { Woolf } from '@woolfuniversity/sdk';
import { useSdk } from './index';
import MoonIcon from './shared/Icons/MoonIcon';
import {
  fetchInstructorDetails,
  getProfile,
  getProfilesList,
  switchProfile,
} from './store/profile/actions';
import styles from './Global.module.css';
import { logout } from './shared/utils/helper';
import LazyLoad from './shared/utils/CustomHooks/LazyLoad';
import AutomatedOneToOneIcon from './shared/Icons/Navbar/AutomatedOneToOneIcon';
import OneToOneIcon from './shared/Icons/Navbar/OneToOneIcon';
import CheckpointIcon from './shared/Icons/Navbar/CheckpointIcon';
import LogoutIcon from './shared/Icons/Navbar/LogoutIcon';
import Dashboard from './pages/Dashboard';
import LabPhaseListIcon from './shared/Icons/LabPhaseListIcon';
import StudentProjectsIcon from './shared/Icons/StudentProjectsIcon';
import LabPhaseFinalApproval from './pages/LabPhaseFinalApproval';
import { getRoles } from './shared/utils/requestHeader';
import EarningsIcon from './shared/Icons/EarningsIcon';
import AttendanceIcon from './shared/Icons/AttendanceIcon';
import OnlineSessionsIcon from './shared/Icons/OnlineSessionsIcon';
import MyCourses from './pages/MyCourses';
import { ExtendedProfile, ProfileListElement } from './store/profile/types';
import { splitProfiles } from './shared/utils/roles';
import RepeatIcon from './shared/Icons/RepeatIcon';
import PrivateRoute from './ProtectedRoute';
import Loader from './shared/components/Loader';
import SunIcon from './shared/Icons/SunIcon';
import { signJwt } from './tokenUtils';
import { ApplicationState } from './store';

const TrackResources = LazyLoad(() => import('./pages/TrackResources'));

const TrackWorkshops = LazyLoad(() => import('./pages/TrackWorkshops'));

const TrackCheckpoints = LazyLoad(() => import('./pages/TrackCheckpoints'));

const TrackOneToOnes = LazyLoad(() => import('./pages/TrackOneToOnes'));

const TrackSaved = LazyLoad(() => import('./pages/TrackSaved'));

const TrackPreview = LazyLoad(() => import('./pages/TrackPreview'));
const TrackOverview = LazyLoad(() => import('./pages/TrackOverview'));
const StoreTrackPreview = LazyLoad(() => import('./pages/StoreTrackPreview'));
const ProfilePage = LazyLoad(() => import('./pages/Profile'));
const StudentProfile = LazyLoad(() => import('./pages/StudentProfile'));
const Checkpoint = LazyLoad(() => import('./pages/Checkpoint'));
const CheckpointList = LazyLoad(() => import('./pages/CheckpointList'));
const AutomatedOneToOneDetails = LazyLoad(
  () => import('./pages/AutomatedOneToOneDetails'),
);
const AutomatedOneToOneList = LazyLoad(
  () => import('./pages/AutomatedOneToOneList'),
);
const FaceToFaceOneToOneList = LazyLoad(
  () => import('./pages/FaceToFaceOneToOneList'),
);
const FaceToFaceOneToOneDetails = LazyLoad(
  () => import('./pages/FaceToFaceOneToOneDetails'),
);
const Earnings = LazyLoad(() => import('./pages/Earnings'));
const StudentList = LazyLoad(() => import('./pages/StudentList'));
const LabPhaseList = LazyLoad(() => import('./pages/LabPhase/LabPhaseList'));
const StudentProjects = LazyLoad(
  () => import('./pages/LabPhase/StudentProjects'),
);
const OneLabPhase = LazyLoad(() => import('./pages/LabPhase/OneLabPhase'));
const InstructorLabPhaseRequestReview = LazyLoad(
  () => import('./pages/LabPhase/InstructorLabPhaseRequestReview'),
);
const InstructorLabPhaseDetails = LazyLoad(
  () => import('./pages/LabPhase/InstructorLabPhaseDetails'),
);

const InstructorLabPhasePhases = LazyLoad(
  () => import('./pages/LabPhase/InstructorLabPhasePhases'),
);
const Attendance = LazyLoad(() => import('./pages/Attendance'));
const Presence = LazyLoad(() => import('./pages/Presence'));
const OnlineSessions = LazyLoad(() => import('./pages/OnlineSessions'));
const OnlineSessionsById = LazyLoad(() => import('./pages/OnlineSessionsById'));
const ActiveOnlineSession = LazyLoad(
  () => import('./pages/ActiveOnlineSession'),
);

const App = () => {
  const dispatch = useDispatch();
  const [isDark, setIsDark] = useState(
    localStorage.getItem('isDark') === 'true',
  );
  const { setSdk } = useSdk();

  const initWoolf = async (userId: string) => {
    const token = await signJwt(
      { alg: 'HS256', typ: 'JWT' },
      {
        id: userId,
        collegeId: `${process.env.REACT_APP_WOOLF_COLLEGE_ID}`,
      },
      `${process.env.REACT_APP_WOOLF_COLLEGE_SECRET}`,
    );
    const localWoolf = await Woolf.create(
      token,
      { widget: { renderOnInit: false }, env: process.env.REACT_APP_WOOLF_ENV },
    );
    setSdk(localWoolf);
  };
  useEffect(() => {
    if (isDark) {
      localStorage.setItem('isDark', 'true');
      document.body.classList.add('dark');
    } else {
      document.body.classList.remove('dark');
      localStorage.setItem('isDark', 'false');
    }
  }, [isDark]);

  useEffect(() => {
    dispatch(getProfile());
    dispatch(fetchInstructorDetails());
  }, [dispatch]);

  const profile = useSelector(
    (state: { profile: { profile: ExtendedProfile } }) => state.profile.profile,
  );
  const fetchInstructorDetailsLoading = useSelector(
    (state: { profile: { fetchInstructorDetailsLoading: boolean } }) => state.profile.fetchInstructorDetailsLoading,
  );

  const profiles = useSelector(
    (state: { profile: { profiles: ProfileListElement[] } }) => state.profile.profiles,
  );

  const preferredLanguage = useSelector((state: ApplicationState) => state.profile.profile.preferredLanguage);

  const role = getRoles();
  const location = useSelector(
    (state: { profile: { location: Location } }) => state.profile.location,
  );

  useEffect(() => {
    if (profile.id) {
      initWoolf(profile.id);
    }
  }, [profile]);

  useEffect(() => {
    if (profile?.email) {
      dispatch(getProfilesList(profile.email));
    }
  }, [dispatch, profile]);

  const redirectToPortalApp = () => {
    const accessToken = localStorage.getItem('token');
    const expires = localStorage.getItem('expires');
    if (accessToken && expires) {
      localStorage.clear();
      window.location.href = `${process.env.REACT_APP_PORTAL_APP_URL
      }?access_token=${encodeURIComponent(
        accessToken,
      )}&expires=${encodeURIComponent(expires)}`;
    }
  };

  useEffect(() => {
    Mixpanel.alias(profile?.id);
    Mixpanel.identify(profile?.id);
    Mixpanel.people.set({
      Email: profile?.email,
      USER_ID: profile?.id,
      phone: profile?.phoneNumber,
      first_name: profile?.firstName,
      last_name: profile?.lastName,
      name: profile?.fullName,
      country_code: location?.country,
      hackerspaceId: location?.id,
      hackerspace: location?.name,
      gender: profile?.gender,
      dateOfBirth: profile?.dateOfBirth,
      CityLocation: profile?.address,
      roles: role,
      platform: 'INSTRUCTOR_APP',
    });
    if (profile?.id) {
      Sentry.setUser({
        email: profile?.email,
        id: profile?.id,
        username: profile?.fullName,
      });
    }
  }, [profile, location, role]);

  const getMultiProfileNavbarElements = () => {
    const result = [];
    const { studentProfiles, instructorProfiles } = splitProfiles(profiles);

    if (instructorProfiles.length > 1) {
      result.push({
        title: 'Switch Hackespace',
        key: uuidv4(),
        children: instructorProfiles?.map((instructorProfile) => ({
          title: instructorProfile?.location?.name,
          key: instructorProfile?.profile?.id,
          active: instructorProfile?.location.id === location.id,
          onClick: () => dispatch(switchProfile(instructorProfile?.profile?.id)),
        })),
        type: 'labelWithDropDown',
      });
    }

    if (studentProfiles.length > 0) {
      result.push({
        key: uuidv4(),
        title: 'Student',
        rightIcon: <RepeatIcon />,
        onClick: () => redirectToPortalApp(),
        type: 'simpleLabel',
      });
    }

    return result;
  };

  useEffect(() => {
    if (profile?.id) {
      localStorage.setItem('language', String(preferredLanguage));
    }
  }, [profile]);

  const isContentCreator = role?.length > 0 && role?.includes('CONTENT_CREATOR');

  const navbarProfile = () => ({
    key: uuidv4(),
    picture: profile?.picture,
    title: profile?.fullName,
    role: 'Instructor',
    children: [
      {
        key: uuidv4(),
        title: 'Account Settings',
        type: 'simpleLabel',
        link: '/profile',
      },
      isContentCreator ? {
        title: 'Go To Titan',
        link: `https://${
          process.env.REACT_APP_TITAN
        }?access_token=${localStorage.getItem('token')}`,
        target: 'blank',
        key: uuidv4(),
        type: 'simpleLabel',
      } : null,
      isContentCreator ? {
        title: 'Go to Athena',
        link: `https://${process.env.REACT_APP_ATHENA_URL}?access_token=${localStorage.getItem('token')}`,
        target: 'athenaTab',
        key: uuidv4(),
        type: 'simpleLabel',
      } : null,
      ...getMultiProfileNavbarElements(),
      {
        key: uuidv4(),
        title: 'Logout',
        rightIcon: <LogoutIcon />,
        onClick: () => logout(),
        type: 'labelLogout',
      },
    ],
  });

  if (fetchInstructorDetailsLoading) return <Loader />;
  return (
    <div className={styles.instructorGlobal}>
      <NavbarTest
        isDark={isDark}
        extraItem={(
          <div className={styles.switchDarkMode}>
            <Switch
              checked={isDark}
              onChange={() => setIsDark((v) => !v)}
              unCheckedChildren={(
                <div className={styles.container}>
                  {isDark ? (
                    <div className={styles.secondContainer}>
                      <SunIcon className={styles.sunIcon} />
                    </div>
                  ) : (
                    <MoonIcon className={styles.moonUncheck} />

                  ) }
                </div>
          )}
              checkedChildren={(
                <div className={styles.secondContainer}>
                  <SunIcon />
                </div>
          )}
            />
          </div>
        )}
        items={[
          {
            label: {
              title: 'Dashboard',
              link: '/',
              key: uuidv4(),
            },
            key: uuidv4(),
            type: 'simpleLabel',
          },
          {
            label: {
              key: uuidv4(),
              title: 'Teach',
              children: [
                {
                  link: '/automated-one-to-one',
                  title: 'Online One to One',
                  description: 'Access Automated one-to-ones',
                  key: uuidv4(),
                  leftIcon: <AutomatedOneToOneIcon />,
                },
                {
                  link: '/one-to-one',
                  title: 'One to One',
                  description: 'Access Face-to-face one-to-ones.',
                  key: uuidv4(),
                  leftIcon: <OneToOneIcon />,
                },
                {
                  title: 'Checkpoint',
                  description: 'access all checkpoints',
                  link: '/checkpoints',
                  key: uuidv4(),
                  leftIcon: <CheckpointIcon />,
                },
                {
                  title: 'Earnings',
                  description: 'access all earnings',
                  link: '/earnings',
                  key: uuidv4(),
                  leftIcon: <EarningsIcon />,
                },
                {
                  title: 'Attendance',
                  description: 'access all attendance',
                  link: '/attendance',
                  key: uuidv4(),
                  leftIcon: <AttendanceIcon />,
                },
                location.isOnlineEnabled
                  ? {
                    title: 'Online sessions',
                    description: 'access all online sessions',
                    link: '/online-sessions',
                    key: uuidv4(),
                    leftIcon: <OnlineSessionsIcon />,
                  }
                  : null,
              ],
            },
            key: uuidv4(),
            type: 'labelWithDropDown',
          },
          {
            label: {
              title: 'My Students',
              link: '/students',
              key: uuidv4(),
            },
            key: uuidv4(),
            type: 'simpleLabel',
          },
          {
            label: {
              key: uuidv4(),
              title: 'LabPhase',
              children: [
                {
                  link: '/lab-phase-list',
                  title: 'Projects List',
                  description: 'List of available projects',
                  key: uuidv4(),
                  leftIcon: <LabPhaseListIcon />,
                },
                {
                  link: '/student-projects',
                  title: "Student's Projects",
                  description: 'The  Students Final projects',
                  key: uuidv4(),
                  leftIcon: <StudentProjectsIcon />,
                },
              ],
            },
            key: uuidv4(),
            type: 'labelWithDropDown',
          },
          {
            label: {
              title: 'My Courses',
              link: '/my-courses',
              key: uuidv4(),
            },
            key: uuidv4(),
            type: 'simpleLabel',
          },
        ]}
        profile={navbarProfile()}
      />

      <Routes>
        <Route path="/" element={<PrivateRoute Component={Dashboard} />} />
        <Route
          path="/automated-one-to-one"
          element={<PrivateRoute Component={AutomatedOneToOneList} />}
        />
        <Route
          path="/one-to-one"
          element={<PrivateRoute Component={FaceToFaceOneToOneList} />}
        />
        <Route
          path="/automated-one-to-one-details/:oneToOneAssignmentId"
          element={<PrivateRoute Component={AutomatedOneToOneDetails} />}
        />
        <Route
          path="/Earnings"
          element={<PrivateRoute Component={Earnings} />}
        />
        <Route
          path="/checkpoints"
          element={<PrivateRoute Component={CheckpointList} />}
        />
        <Route
          path="/checkpoint/:id"
          element={<PrivateRoute Component={Checkpoint} />}
        />
        <Route
          path="/one-to-one/:oneToOneAssignmentId"
          element={<PrivateRoute Component={FaceToFaceOneToOneDetails} />}
        />
        <Route
          path="/students"
          element={<PrivateRoute Component={StudentList} />}
        />
        <Route
          path="/students/:id/tracks/:trackId/groups/:groupId"
          element={<PrivateRoute Component={StudentProfile} />}
        />
        <Route
          path="/track-overview/:trackId"
          element={<PrivateRoute Component={TrackOverview} />}
        />
        <Route
          path="/lab-phase-list"
          element={<PrivateRoute Component={LabPhaseList} />}
        />
        <Route
          path="/lab-phase-list/:id"
          element={<PrivateRoute Component={OneLabPhase} />}
        />
        <Route
          path="/student-projects"
          element={<PrivateRoute Component={StudentProjects} />}
        />
        <Route
          path="/track-overview/:trackId/node/:nodeId/kind/:kind"
          element={<PrivateRoute Component={TrackPreview} />}
        />
        <Route
          path="/store-overview/:trackId/node/:nodeId/kind/:kind"
          element={<PrivateRoute Component={StoreTrackPreview} />}
        />
        <Route
          path="/track-resources/:trackId/node/:nodeId/kind/:kind"
          element={<PrivateRoute Component={TrackResources} />}
        />

        <Route path="/profile" element={<ProfilePage />} />
        <Route
          path="/instructor-labPhase-request-review/:labPhaseID/:studentID"
          element={<PrivateRoute Component={InstructorLabPhaseRequestReview} />}
        />
        <Route
          path="/instructor-labPhase-phases/:id"
          element={<PrivateRoute Component={InstructorLabPhasePhases} />}
        />
        <Route
          path="/attendance"
          element={<PrivateRoute Component={Attendance} />}
        />
        <Route
          path="/attendance/:sessionInstanceId"
          element={<PrivateRoute Component={Presence} />}
        />

        <Route
          path="/instructor-labPhase-details/:labPhaseAssignmentId/:phaseId"
          element={<PrivateRoute Component={InstructorLabPhaseDetails} />}
        />

        <Route
          path="/saved-tracks/:trackId"
          element={<PrivateRoute Component={TrackSaved} />}
        />
        <Route
          path="/checkpoints-tracks/:trackId"
          element={<PrivateRoute Component={TrackCheckpoints} />}
        />

        <Route
          path="/one-to-ones-tracks/:trackId"
          element={<PrivateRoute Component={TrackOneToOnes} />}
        />
        <Route
          path="/workshops-tracks/:trackId"
          element={<PrivateRoute Component={TrackWorkshops} />}
        />
        <Route
          path="/instructor-labPhase-approval/:labPhaseAssignmentId"
          element={<PrivateRoute Component={LabPhaseFinalApproval} />}
        />
        <Route
          path="/online-sessions"
          element={<PrivateRoute Component={OnlineSessions} />}
        />
        <Route
          path="/online-sessions/:id"
          element={<PrivateRoute Component={OnlineSessionsById} />}
        />
        <Route
          path="/online-sessions/:id/active"
          element={<PrivateRoute Component={ActiveOnlineSession} />}
        />
        <Route
          path="/my-courses"
          element={<PrivateRoute Component={MyCourses} />}
        />
      </Routes>
    </div>
  );
};

export default App;
