import { Buffer } from 'buffer';

// Utility function to base64url encode a string
export const base64url = (input: string | ArrayBuffer): string => {
  let str: string;
  if (typeof input === 'string') {
    str = input;
  } else {
    // Convert ArrayBuffer to string
    const uint8Array = new Uint8Array(input);
    str = String.fromCharCode(...uint8Array);
  }

  return Buffer.from(str, 'binary')
    .toString('base64')
    .replace(/=/g, '') // Remove any trailing '='
    .replace(/\+/g, '-') // Replace '+' with '-'
    .replace(/\//g, '_'); // Replace '/' with '_'
};

// Types for JWT Header and Payload
interface JwtHeader {
    alg: string;
    typ: string;
}

interface JwtPayload {
    id: string;
    collegeId: string;
}

// Function to create the JWT signature (HMAC-SHA256)
export const signJwt = async (header: JwtHeader, payload: JwtPayload, secret: string) => {
  // 1. Base64url encode the header and payload
  const encodedHeader = base64url(JSON.stringify(header));
  const encodedPayload = base64url(JSON.stringify(payload));

  // 2. Create the unsigned token (header.payload)
  const token = `${encodedHeader}.${encodedPayload}`;

  // 3. Sign the token with the secret using HMAC-SHA256
  const encoder = new TextEncoder();
  const key = await crypto.subtle.importKey(
    'raw',
    encoder.encode(secret),
    { name: 'HMAC', hash: 'SHA-256' },
    false,
    ['sign'],
  );

  const signatureArrayBuffer = await crypto.subtle.sign(
    'HMAC',
    key,
    encoder.encode(token),
  );

  // 4. Base64url encode the signature
  const signature = base64url(Buffer.from(signatureArrayBuffer));

  // 5. Return the full JWT
  return `${token}.${signature}`;
};
