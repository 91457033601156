/* eslint-disable @typescript-eslint/no-explicit-any */
import parseUrl from 'url-parse';
import moment from 'moment';
import { isEmpty } from 'lodash';
import {
  Group, LabPhaseAssignment, PayCheck, ProductSchedule, Profile, Review, StudentRecord, StudentSubscription, Track,
} from '@gomycode/types-utils/Types/types';
import { getCurrencyByName } from './Countries/countries.helper';
import { AllPayCheck } from '../../pages/Earnings/Earnings';

export const PHASE_HISTORY_EVENT_TYPES = {
  unlock: 'Unlocked',
  submission: 'Submitted',
  review: 'Rejected',
  approval: 'Approved',
};
export const EXCEPTION_TYPES = [
  'Content',
  'Workshop',
  'Event',
  'Support',
  'Extra hours',
];
export const renderAmount = (amount: string, country: string) => {
  const currency = getCurrencyByName(country);
  if (amount === undefined || country === undefined) {
    return '0';
  }
  return `${amount} ${currency}`;
};
type CustomPayCheck = {
  Date: string;
  WorkedHours: number;
  Type: number;
  Label: string;
  HourlyRate: number;
  id: string;
  financialRecord: {
    hourlyPay: number;
  }
  payCheck: PayCheck;
}
export const transformExceptions = (payCheck: AllPayCheck) => payCheck?.payCheck?.exceptions
  ?.map((e) => JSON.parse(e))
  .map((e: CustomPayCheck) => {
    const startDate = moment(e.Date);
    const dueDate = moment(startDate).add(e.WorkedHours * 60, 'minutes');
    const duration = moment.duration(dueDate.diff(startDate));

    const schedule = `${moment(e.Date)?.format('HH:mm')} - ${
      `${Number(moment(e.Date)?.format('HH '))
        + e.WorkedHours
      }:${
        moment(e.Date)?.format('mm ')}`
    }`;
    return {
      trackName: e.Type === 4 && e.Label ? e.Label : EXCEPTION_TYPES[e.Type],
      groupName: 'N/A',
      schedule,
      date: moment(e.Date)?.format('YYYY/MM/DD'),
      logged: `${duration.asSeconds() <= 0 ? '0H' : duration.toISOString().slice(2)}`,
      presenceStatus: 1,
      session: 'N/A',
      total: Number(e?.HourlyRate) * Number(e?.WorkedHours),
      hourly: e.HourlyRate,
      key: e.id,
    };
  });
// Display Table Data
export function getTableData(arr: any, payCheck: AllPayCheck) {
  const result = arr?.map((item: any) => {
    const startDate = moment(item.sessionInstance.startDate);
    const dueDate = moment(item.sessionInstance.dueDate);
    const absentTimeInSeconds = !isEmpty(item.absences) && item.absences.length > 0
      ? item.absences
        .map((x: { dueDate: moment.MomentInput; startDate: moment.MomentInput; }) => moment
          .duration(moment(x.dueDate).diff(moment(x.startDate)))
          .asSeconds())
        .reduce((acc: number, cur: number) => cur + acc)
      : 0;
    const breakDurationInSeconds = !isEmpty(item.timeslots) && item.timeslots.length > 0
      ? item.timeslots
        .map((x: { excludedTimeInSeconds: any; }) => x.excludedTimeInSeconds)
        .reduce((acc: number, cur: number) => cur + acc)
      : 0;
    const duration = moment
      .duration(dueDate.diff(startDate))
      .subtract(breakDurationInSeconds + absentTimeInSeconds, 'seconds');

    const totalAmount = Math.max(
      (duration.asSeconds() / 3600) * (payCheck?.financialRecord?.hourlyPay ?? 0),
      0,
    );
    return {
      key: item.sessionInstance.id,
      trackName: item.track.name,
      groupName: item.group.name,
      schedule: ` ${startDate.format(
        'HH:mm',
      )} - ${dueDate.format('HH:mm')}`,
      date: item.sessionInstance.startDate,
      logged: `${duration.asSeconds() <= 0 ? '0H' : duration.toISOString().slice(2)}`,
      presenceStatus: item.partOf.presenceStatus,
      session: item.timeslots[0].isExtra,
      hourly: payCheck?.financialRecord?.hourlyPay,
      total: totalAmount.toFixed(2),
    };
  });
  const data = [...(result || []), ...(transformExceptions(payCheck) || [])];
  return data;
}
export const getCurrentQueryStringParameter = (
  param: string,
  url: string = window.location.href,
): string | undefined => {
  const parsedUrl = parseUrl(url, true);
  return parsedUrl.query[param];
};

export const logout = () => {
  localStorage.clear();
  const redirectURL = process.env.REACT_APP_PORTAL_APP_URL || window.location.origin;

  const redirectionUrl = process.env.REACT_APP_AUTH_METHOD === 'KEYCLOAK'
    ? `${process.env.REACT_APP_KEYCLOAK_URL}/logout?redirectUrl=${encodeURIComponent(redirectURL)}`
    : `${process.env.REACT_APP_AUTH_APP_URL}?logout=true`;
  window.location.href = redirectionUrl;
};

export const CASE_INSENSITIVE_OPERATORS = ['contains', 'not_contains'];

type transformDataType = {
  subscribedToRelationships: StudentSubscription[];
  diploma: [];
  labPhaseAssignments: LabPhaseAssignment[];
  tracks: [];
  student: Profile[];
  groups: Group[];
  defaultTrack: Track[];
  productSchedules: ProductSchedule[];
  studentRecords: StudentRecord[];
}
export const transformInstructorStudentsDataTable = (data: any) => {
  if (data) {
    const transformedData: any = [];
    if (data) {
      data.forEach((item: transformDataType) => {
        const diplomas = item.subscribedToRelationships.map(
          (el: { hasDiploma: boolean }) => (el.hasDiploma ? item.diploma.shift() : null),
        );

        const LabPhaseAssignments = item.subscribedToRelationships.map(
          (el: { hasLabPhaseAssignment: boolean }) => (el.hasLabPhaseAssignment ? item.labPhaseAssignments.shift() : null),
        );

        item.tracks.forEach((track: Track, i: number) => {
          const transformedItem = {
            student: item.student,
            track,
            groups: item.groups[i] || null,
            defaultTrack: item.defaultTrack,
            subscribedToRelationships:
              item.subscribedToRelationships[i] || null,
            productSchedules: item.productSchedules[i] || null,
            studentRecords: item.studentRecords[i] || null,

            diploma: diplomas[i],
            labPhaseAssignments: LabPhaseAssignments[i],
          };
          transformedData.push(transformedItem);
        });
      });
    }
    return transformedData;
  }
  return [];
};
export const SIDE_BAR_STEPS_MAP = {
  OverviewPhase: 0,
  ObjectiveInstruction: 1,
  Resources: 2,
  CriteriaScore: 3,
  LinkSubmissionReport: 4,
  History: 5,
  Content: 6,
};
type LabPhaseAssignmentType = {
  labPhaseAssignment: LabPhaseAssignment;
}
export const getNextStudent = (
  labPhaseAssignments: LabPhaseAssignmentType[],
  currentLabPhaseId: string | undefined,
) => labPhaseAssignments.reduce((acc, el, index, arr) => {
  if (el.labPhaseAssignment.id === currentLabPhaseId) {
    if (arr[index + 1]) return arr[index + 1].labPhaseAssignment.id;
    return arr[0].labPhaseAssignment.id;
  }
  return acc;
}, '');

type DateType = {
  date: string;
  type: string;
}

type Reviews = {
  review: Review;
  reviewer: Profile;
}
export const getHistory = (phase: any, reviews: Reviews[]) => {
  let studentPhaseHistory = [];

  const {
    lastSubmissionDates,
    unlockDate,
    status,
    approvalDate,
    lastSubmissions,
    submissionDate,
    url,
    lastReportSubmissions,
    studentReport,
  } = phase;
  const unlockEvent = {
    type: PHASE_HISTORY_EVENT_TYPES.unlock,
    date: moment(unlockDate).format('x'),
  };

  const newsubmissionDates = !isEmpty(phase) ? lastSubmissionDates : [];
  const newSubmissionReports = !isEmpty(phase) ? lastReportSubmissions : [];

  const firstSubmissionDate = newsubmissionDates.length === 0 ? submissionDate : newsubmissionDates[0];
  const firstSubmissionReport = newSubmissionReports.length === 0 ? studentReport : newSubmissionReports[0];
  const Urls = !isEmpty(phase) ? lastSubmissions : [];
  const firstUrl = Urls.length === 0 ? url : Urls[0];
  const submissionEvent = {
    type: PHASE_HISTORY_EVENT_TYPES.submission,
    date: status > 1 ? moment(firstSubmissionDate).format('x') : null,
    url: firstUrl,
    report: firstSubmissionReport,
  };

  studentPhaseHistory = [unlockEvent, submissionEvent];
  const submissionData: DateType[] = [];
  if (newsubmissionDates.length > 0) {
    const updateEvents = newsubmissionDates
      ?.slice(1)
      ?.concat([submissionDate])
      ?.map((ue: string) => ({
        type: PHASE_HISTORY_EVENT_TYPES.submission,
        date: moment(ue).format('x'),
        // url:ue.
      }));

    if (lastSubmissions.length > 0) {
      const updateEventsUrl = lastSubmissions
        .slice(1)
        .concat(url)
        .map((el: string) => ({ url: el }));

      const updateEventsReport = lastReportSubmissions
        ?.slice(1)
        ?.concat(studentReport)
        ?.map((el: string) => ({ report: el }));

      updateEvents?.map((el: DateType, i: number) => submissionData.push({
        ...el,
        ...updateEventsUrl[i],
        ...updateEventsReport[i],
      }));
      studentPhaseHistory.push(...submissionData);
    }
  }
  studentPhaseHistory.push({
    date: status >= 4 ? moment(approvalDate).format('x') : null,

    ...phase,
    status,
    type: PHASE_HISTORY_EVENT_TYPES.approval,
  });
  if (!isEmpty(reviews)) {
    const reviewEvents = reviews.map((re: Reviews) => ({
      type: PHASE_HISTORY_EVENT_TYPES.review,
      date: moment(re.review.commentDate).format('x'),

      comment: re.review.comment,
      reviewer: re.reviewer.fullName,
      reviewerPicture: re.reviewer.picture,
    }));
    studentPhaseHistory.push(...reviewEvents);
  }

  return studentPhaseHistory;
};
export const lockedPhase = 0;
export const unlockedPhase = 1;
export const submittedPhase = 2;
export const rejectedPhase = 3;
export const approvedPhase = 4;
