import React, { useEffect } from 'react';
import { Button } from '@gomycode/design-system';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import styles from './TrackCard.module.css';
import RightArrowSvg from '../../../shared/Icons/RightArrow';
import ClockSvg from '../../../shared/Icons/Clock';
import ListRadioIcon from '../../../shared/Icons/TrackOverviewIcons/ListRadio';
import {
  fetchSkeleton,
  setNavigationIndex,
} from '../../../store/trackPreview/actions';
import { navigationKeys } from '../../../shared/components/NavigationMenu/NavigationMenu';
import AssessmentIcon from '../../../shared/Icons/TrackOverviewIcons/Assessment';
import { DashboardCustomGuild } from '../../../store/groups/types';
import { Node, TrackStats } from '../../../store/trackPreview/types';

interface ITrackCardInterface {
  data: DashboardCustomGuild;
  showAction?: boolean;
  showDetails?: boolean;
  superSkillCount?: number;
}

const TrackCard: React.FC<ITrackCardInterface> = ({
  data,
  showAction,
  showDetails,
  superSkillCount,
}) => {
  const trackStats = useSelector(
    (state: {
      trackPreview: {
        trackStats: TrackStats;
      };
    }) => state.trackPreview.trackStats,
  );

  const diffDays = Math.ceil(
    moment(data?.opening?.dueDate).diff(moment(data?.opening?.startDate))
      / (1000 * 60 * 60 * 24)
      / 7,
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchSkeleton({ query: [], trackId: data.track.id }));
  }, [data.track.id, dispatch]);
  const skeleton = useSelector(
    (state: {
      trackPreview: {
        skeleton: Node;
      };
    }) => state.trackPreview.skeleton,
  );
  return (
    <div className={styles.trackCard}>
      <div className={styles.leftSection}>
        <img src={data.track.image} alt="track name" className={styles.image} />
        <div className={styles.infos}>
          <h1 className={styles.title}>{data.track.name}</h1>
          <p className={styles.metrics}>
            {data.records.length}
            {' '}
            Active Guilds •
            {data.studentCount}
            {' '}
            Students
            trained
          </p>
          <div className={styles.actionsSection}>
            {showAction && (
            <Link to={`/track-overview/${data.track.id}`}>
              <p className={styles.trackDetailsAction}>
                Track Details
                <RightArrowSvg className={styles.trackDetailsIcon} />
              </p>
            </Link>
            )}
          </div>
          {showDetails && (
            <div className={styles.detailsContainer}>
              <p className={styles.details}>
                <ClockSvg className={styles.detailsIcon} />
                {' '}
                Duration :
                {diffDays}
                {' '}
                Weeks
              </p>
              <p className={styles.details}>
                <ListRadioIcon className={styles.detailsIcon} />
                {' '}
                Super skills :
                {superSkillCount}
              </p>
              <p className={styles.details}>
                <AssessmentIcon className={styles.detailsIcon} />
                {' '}
                Assessment :
                {trackStats?.assessments}
              </p>
            </div>
          )}
        </div>
      </div>
      {skeleton?.children?.length && (
        <Link
          to={`/track-overview/${data.track.id}/node/${skeleton?.children[0]?.children[0]?.id}/kind/${skeleton?.children[0]?.children[0]?.kind}`}
        >
          <Button
            onClick={() => dispatch(setNavigationIndex(navigationKeys.course))}
            className={styles.button}
            priority="secondary"
          >
            Preview course
          </Button>
        </Link>
      )}
    </div>
  );
};

TrackCard.defaultProps = {
  showAction: false,
  showDetails: false,
  superSkillCount: 0,
};

export default TrackCard;
