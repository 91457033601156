import {
  generateActionWithMultipleValues,
  generateActionWithId,
  generateActionWithBodyAndId,
  generateActionWithBody,
} from '../../shared/utils/generic-saga';
import constants from './constants';

const actions = {
  getUnclosedCheckpoints: generateActionWithMultipleValues(
    constants.getUnclosedCheckpoints.request,
  ),
  getClosedCheckpoints: generateActionWithMultipleValues(
    constants.getClosedCheckpoints.request,
  ),
  getUnlockedCheckpoints: generateActionWithMultipleValues(
    constants.getUnclosedCheckpoints.request,
  ),
  getAllCheckpoints: generateActionWithMultipleValues(
    constants.getAllCheckpoints.request,
  ),
  getCheckpointById: generateActionWithId(constants.getCheckpointById.request),
  addReview: generateActionWithBodyAndId(constants.addReview.request),
  approveCheckpoint: generateActionWithBodyAndId(
    constants.approveCheckpoint.request,
  ),
  approveWoolfCheckpoint: generateActionWithMultipleValues(
    constants.approveWoolfCheckpoint.request,
  ),
  updateRateStudentTabStatus: generateActionWithBody(
    constants.updateRateStudentTabStatus.request,
  ),
  fetchCheckpointTopStudents: generateActionWithId(
    constants.fetchCheckpointTopStudents.request,
  ),
  showValidateCheckpointModal: generateActionWithBody(
    constants.showValidateCheckpointModal.request,
  ),
  showDeadlineModal: generateActionWithBody(
    constants.showDeadlineModal.request,
  ),
};

export default actions;
